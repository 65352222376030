import { NgModule } from '@angular/core';
import { RouterModule, Routes, InitialNavigation, ExtraOptions } from '@angular/router';
import { AuthGuard } from './auth-guard.guard';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path:'login',
    loadChildren: () => import('./login/login.module').then(m=>m.LoginModule)
  },
  {
    path:'home',
    canActivate:[AuthGuard],
    loadChildren: () => import('./home/home.module').then(m=>m.HomeModule)
  }
];

const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledNonBlocking',
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
