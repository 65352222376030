import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { UtilidadesService } from './services/utilidades.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  
  constructor(private router:Router, 
              private loginService:LoginService) {
    this.loginService._initializeToken();
    setTimeout(() => {
      this.loginService.validateToken().subscribe({
        next: response => {
          if (response.valido == false) {
            this.loginService.doLogout();
          } else {
            this.loginService.user = response.data;
            let currentUrl = this.router.url;
            if (currentUrl == '/') {
              currentUrl ="/home";
            }
            this.router.navigate([currentUrl]);
          }
        },
        error: () => {
          this.router.navigate(['/login']);
        }
      })
    }, 800);
  }
}
